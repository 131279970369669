<template>
    <section>
        <div id="mobile_menu" class="mobileonly">
            <div id="top">
                <div id="logo" />
                <div id="button_close" @click="close_menu()" />
            </div>
            <div id="menu">
                <div class="item" @click="go_to_section('#section_home', true)">HOME</div>
                <div class="item" @click="go_to_section('#section_about', true)">ABOUT</div>
                <div class="item" @click="go_to_section('#section_about #content3', true)">VISION</div>
                <div class="item" @click="go_to_section('#section_roadmap', true)">ROADMAP</div>
                <div class="item" @click="go_to_section('#section_contact', true)">CONTACT</div>
            </div>
            <div id="bottom">© 2023 BP. All rights reserved.</div>
        </div>
        <div id="sticky_menu" class="desktoponly">
            <div id="logo" />
            <div id="menu">
                <div class="item" @click="go_to_section('#section_home')">HOME</div>
                <div class="item" @click="go_to_section('#section_about')">ABOUT</div>
                <div class="item" @click="go_to_section('#section_about #content3')">VISION</div>
                <div class="item" @click="go_to_section('#section_roadmap')">ROADMAP</div>
                <div class="item" @click="go_to_section('#section_contact')">CONTACT</div>
            </div>
        </div>
        <div id="content_section">
            <div class="section" id="section_home">
                <div id="background1" />
                <div id="logoandmenu">
                    <div id="logo" />
                    <div id="menu" class="desktoponly">
                        <div class="item" @click="go_to_section('#section_home')">HOME</div>
                        <div class="item" @click="go_to_section('#section_about')">ABOUT</div>
                        <div class="item" @click="go_to_section('#section_about #content3')">VISION</div>
                        <div class="item" @click="go_to_section('#section_roadmap')">ROADMAP</div>
                        <div class="item" @click="go_to_section('#section_contact')">CONTACT</div>
                    </div>
                    <div id="button_menu" class="mobileonly" @click="open_menu()"></div>
                </div>
                <div id="content1">Blockchain<br />Healthcare Platform</div>
                <div id="content2" class="desktoponly">
                    <p>
                        BP는 환자의 데이터 자기 결정권과 의무 기록의 탈중앙화를 지원하는 블록체인 기반<br />
                        헬스케어 데이터 생태계를 만들어 글로벌 적이고
                        <span
                            >안전한 데이터를 만들어 개인이 본인의<br />
                            의료 정보에 쉽게 접근하고 데이터 활용을 가능하게 하는 것이 목표입니다.</span
                        >
                    </p>
                </div>
                <div id="content2" class="mobileonly">
                    <p>
                        BP는 환자의 데이터 자기 결정권과 의무 기록의 탈중앙화를<br />
                        지원하는 블록체인 기반 헬스케어 데이터 생태계를<br />
                        만들어 글로벌 적이고
                        <span
                            >안전한 데이터를 만들어 개인이 본인의<br />
                            의료 정보에 쉽게 접근하고 데이터 활용을<br />
                            가능하게 하는 것이 목표입니다.</span
                        >
                    </p>
                </div>
                <div id="content3">
                    <a href="./download/BP_Whitepaper.pdf" download><div>백서 다운로드</div></a>
                    <a href="https://t.me/BodyProfilechat_chat"><div class="desktoponly">공식 텔레그램 입장</div></a>
                </div>
                <div id="content4" />
            </div>
            <div class="section" id="section_about">
                <div id="content1" class="title">About</div>
                <div id="content2" class="desc desktoponly">
                    바피는 문제점들에 대해 전체적으로 솔루션을 제공하고 있으며, 현대화 시대의 현재 생활 기준으로 산업을 향상 시키려고 합니다.<br />
                    또한 문제점들을 개선하는데에 있어 개인적인 부분에 부합하는 의료 서비스를 만들고자 하는 목표이며,<br />
                    개인이나 환자는 물론 공중위생, 의료 공급자, 민간기업 조직에도 지속적으로 이익을 제공하는 것이 목표입니다.
                </div>
                <div id="content2" class="desc mobileonly">
                    바피는 문제점들에 대해 전체적으로 솔루션을 제공하고 있으며,<br />
                    현대화 시대의 현재 생활 기준으로 산업을 향상 시키려고 합니다.<br />
                    또한 문제점들을 개선하는데에 있어 개인적인 부분에 부합하는<br />
                    의료 서비스를 만들고자 하는 목표이며,<br />
                    개인이나 환자는 물론 공중위생, 의료 공급자, 민간기업 조직에도<br />
                    지속적으로 이익을 제공하는 것이 목표입니다.
                </div>
                <div id="image1" />
                <div id="slide" class="desktoponly">
                    <div id="item" v-for="(item, index) in about_items" :key="index">
                        <div id="center_circle" :style="{ backgroundImage: 'url(./images/' + item.image + ')' }"></div>
                        <div id="title">{{ item.text }}</div>
                    </div>
                </div>
                <div id="slide" class="mobileonly">
                    <swiper class="mySwiper" :slidesPerView="'auto'" :spaceBetween="21" :pagination="pagination" :modules="modules">
                        <swiper-slide v-for="(item, index) in about_items" :key="index">
                            <div id="item">
                                <div id="center_circle" :style="{ backgroundImage: 'url(./images/' + item.image + ')' }"></div>
                                <div id="title">{{ item.text }}</div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div id="content3" class="title">BLOCKCHAIN SYSTEM</div>
                <div id="content4" class="desc desktoponly">
                    바피 블록체인은 헬스케어 데이터와 정보 통합 및 활용을 위한 플랫폼입니다. 개인이 헬스케어 데이터, 정보의 제공과 관리의 주체로서,<br />
                    개인정보 제공 동의를 거친 플랫폼에 안전하게 취합되며, 개인에게는 바피 토큰이 보상으로 지급됩니다.
                </div>
                <div id="content4" class="desc mobileonly">
                    바피 블록체인은 헬스케어 데이터와 정보 통합 및 활용을 위한<br />
                    플랫폼입니다. 개인이 헬스케어 데이터, 정보의 제공과 관리의<br />
                    주체로서, 개인정보 제공 동의를 거친 플랫폼에 안전하게<br />
                    취합되며, 개인에게는 바피 토큰이 보상으로 지급됩니다.
                </div>
                <div id="backcoin1" />
                <div id="backcoin2" />
                <div id="backcoin3" />
                <div id="backcoin4" />
                <div id="image_blockchain_system" class="desktoponly" />
                <div id="image_blockchain_system" class="mobileonly">
                    <div id="zoom_button" @click="open_zoom()"></div>
                </div>
                <div id="content5" class="title">TOKEN DISTRIBUTION</div>
                <div id="content6" class="desc desktoponly">
                    바피는 생태계 참여자 간 상호작용을 위한 매개체이며, 참여자들의 자발적인 데이터 입력을 위한 인센티브, 데이터 사용에 대한 비용 지급,<br />
                    분쟁 중재의 대가, 서비스 제공에 대한 지급, 계약 이행의 보증을 위한 담보 용도로 사용을 한다.
                </div>
                <div id="content6" class="desc mobileonly">
                    바피는 생태계 참여자 간 상호작용을 위한 매개체이며,<br />
                    참여자들의 자발적인 데이터 입력을 위한 인센티브, 데이터 사용에<br />
                    대한 비용 지급, 분쟁 중재의 대가, 서비스 제공에 대한 지급,<br />
                    계약 이행의 보증을 위한 담보 용도로 사용을 한다.
                </div>
                <div id="content7">
                    <div id="graph" />
                    <div id="index" />
                </div>
            </div>
            <div class="section" id="section_roadmap">
                <div id="content1" class="title">ROADMAP</div>
                <div id="timeline" class="desktoponly" />
                <div id="slide" class="desktoponly">
                    <div id="item" v-for="(item, index) in roadmap_items" :key="index">
                        <div id="bullet" />
                        <div id="title" v-html="item.title" />
                        <div id="text" v-html="item.text" />
                    </div>
                </div>
                <div id="slide" class="mobileonly">
                    <div id="timeline" />
                    <div id="items">
                        <div id="item" v-for="(item, index) in roadmap_items" :key="index">
                            <div id="bullet" />
                            <div id="title" v-html="item.title" />
                            <div id="text" v-html="item.text" />
                        </div>
                    </div>
                </div>
                <div id="progress_out">
                    <div id="progress_in"></div>
                </div>

                <!-- <div id="slide" class="mobileonly">
                    <swiper class="mySwiper2" :slidesPerView="'auto'" :spaceBetween="14" >
                        <swiper-slide v-for="(item, index) in roadmap_items" :key="index">
                            <div id="item">
                                <div id="bullet" />
                                <div id="title" v-html="item.title" />
                                <div id="text" v-html="item.text" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div> -->
            </div>
            <div class="section" id="section_contact">
                <div id="content1">Contact</div>
                <div id="content2">비지니스 제안 및 문의는 공식 텔레그램으로 연락해주십시오.</div>
                <a href="https://t.me/BodyProfilechat_chat"><div id="button_telegram"></div></a>
            </div>
            <div id="section_bottom">
                <div id="logo" />
                <div id="text">© 2023 BP. All rights reserved.</div>
            </div>
        </div>
        <div id="zoom_image" class="mobileonly">
            <div id="zoom_image_container">
                <img src="@/assets/images/image_system.svg" width="300" height="400" />
            </div>
            <div id="zoom_close" @click="close_zoom()"></div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import Panzoom from "@panzoom/panzoom";
// import "swiper/css";
import "swiper/css/pagination";
let b_show_sticky_menu = false;

export default {
    name: "App",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiper: null,
            panzoom : null,
            about_items: [
                {
                    image: "icon_private_patient.svg",
                    text: "개인, 환자"
                },
                {
                    image: "icon_medical_provider.svg",
                    text: "의료 공급자"
                },
                {
                    image: "icon_civil_company.svg",
                    text: "민간기업"
                }
            ],
            roadmap_items: [
                { title: "2023 Q1", text: "BP 설립<br/>BP 지갑 런칭<br/>해외 거래소 상장<br/>DApp 런칭<br/>원격 진료 서비스 런칭<br/>전자처방/약 배달 서비스 준비" },
                { title: "2023 Q2", text: "국내 거래소 상장<br/>해외 거래소 상장<br/>헬스케어 NFT 백서 공개<br/>헬스케어 디바이스 솔루션 출시<br/>BP 플랫폼 메타버스 공개<br/>2nd DApp런칭" },
                { title: "2023 Q3", text: "클라우드 블록체인 스토리지 구축<br/>3nd DApp런칭<br/>의료기록 지원 시작<br/>글로벌 DApp 개발<br/>헬스케어 NFT, M2E 서비스 준비" },
                { title: "2023 Q4", text: "3rd Party Network 서비스<br/>AI 헬스케어 솔루션 개발<br/>헬스케어 NFT 제휴처 공개<br/>병원 PB 런칭<br/>AI기반 Q&A서비스 런칭" },
                {
                    title: "2024 Q1",
                    text: "AI기반 헬스케어 솔루션 추가 개발<br/>글로벌 DApp 고도화<br/>글로벌 헬스케어 데이터 플랫폼<br/>메타버스 플랫폼 추가 공개<br/>헬스케어 NFT DAO 시스템 공개<br/>글로벌 헬스 플랫폼 개발<br/>디지털 치료제 서비스 개발<br/>헬스 메타버스 플랫폼 추가 공개"
                },
                {
                    title: "2024 Q2",
                    text: "글로벌 헬스케어 데이터 통합 플랫폼<br/>헬스케어 데이터 통계 & 검색 서비스<br/>메타버스 플랫폼 추가구축<br/>헬스케어 데이터 마켓 플레이스 런칭<br/>유전자 NFT 거래소 런칭<br/>메타버스 월렛 앱 출시<br/>메타버스 플랫폼 정식 버전 공개<br/>클라우드 인프라 플랫폼 연동"
                }
            ],

            modules: [Pagination],
            pagination: {
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>';
                }
            }
        };
    },

    mounted() {
        document.addEventListener("scroll", () => {
            var scroll = $(window).scrollTop();
            // console.log(scroll);
            if ($(window).width() < 700) return;
            if (scroll > 120 && !b_show_sticky_menu) this.show_sticky_menu();
            if (scroll < 120 && b_show_sticky_menu) this.hide_sticky_menu();
        });

        const resize_handler = () => {
            let windowsize = window.innerWidth;
            if (windowsize > 1920) windowsize = 1920;
            let scale_rate = windowsize / 1920;
            $("body").css("--scale-value", scale_rate);
            $("body").css("--translate-value", "0%");
            $("body").css("--section-height", 6558 * scale_rate + "px");
            if(windowsize < 700)
                $("#sticky_menu").css("display","none");
        };
        $(window).on("resize", resize_handler);

        let jelement = $("#section_roadmap #slide.mobileonly");

        jelement.on("scroll", () => {
            if ($(window).width() > 700) return;
            let ele = document.querySelector("#section_roadmap #slide.mobileonly");
            let max_scroll = ele.scrollWidth - ele.clientWidth;
            let var_scroll = ele.scrollLeft;
            let per = (var_scroll * 100) / max_scroll + "%";
            $("#progress_in").css("width", per);
            // console.log(ele.scrollWidth - ele.clientWidth);
            // console.log(document.querySelector("#section_roadmap #slide.mobileonly").scrollWidth);
            // console.log(jelement.scrollLeft());
        });

        resize_handler();
    },
    methods: {
        open_zoom() {
            $("div#zoom_image").fadeIn();
            this.panzoom = Panzoom(document.querySelector("div#zoom_image_container"), { contain: "outside" });
        },
        close_zoom() {
            $("div#zoom_image").fadeOut();
            this.panzoom.destroy();
        },
        download(link) {
            window.open(link);
        },
        openlink(link) {
            window.open(link);
        },
        open_menu() {
            $("div#mobile_menu").animate({
                left: 0
            });
        },
        close_menu() {
            $("div#mobile_menu").animate({
                left: "100%"
            });
        },

        on_swiper(s) {
            this.swiper = s;
        },

        go_to_section(section_id, close_menu = false) {
            if (close_menu) this.close_menu();
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(section_id).offset().top
                },
                500
            );
        },

        show_sticky_menu() {
            b_show_sticky_menu = true;
            $("#sticky_menu").fadeIn(200);
            // console.log("show");
        },

        hide_sticky_menu() {
            b_show_sticky_menu = false;
            $("#sticky_menu").fadeOut(200);
            // console.log("hide");
        },

        on_mouseover_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "visible"
            });
            // console.log("on", pid);
        },

        on_mouseout_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "hidden"
            });
            // console.log($(div_id + " div#sub_div"), "off", pid);
        },
        on_but_slide_next() {},
        on_but_slide_prev() {
            // this.swiper.slidePrev();
        }
    }
};
</script>
<style></style>

<style lang="scss" src="@/assets/css/common.scss"></style>
<style lang="scss" src="@/assets/css/desktop.scss"></style>
<style lang="scss" src="@/assets/css/mobile.scss"></style>
